import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import {motion} from 'framer-motion'
import {Box, Skeleton, Tab, Tabs} from '@mui/material'
import ArchiveUsers from './TapArchives/ArchiveUsers'
import useAuthContext from '../../../Auth/AuthContext'
import { KTSVG } from '../../../../_metronic/helpers'

const ArchivesHome = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [value, setValue] = useState(0)
  const {check_role} = useAuthContext()
  const [permissions, setPermissions] = useState({
    restoreUsers: false,
    forceDeleteUsers: false,
    archiveUsers: false,
  })

 

  useEffect(() => {
    const archiveUsersPermission = check_role('archive-users')
    if (!archiveUsersPermission) {
      navigate('/')
      return
    }
    setPermissions({
      forceDeleteUsers: check_role('force-delete-users'),
      archiveUsers: archiveUsersPermission,
      restoreUsers: check_role('restore-users'),
    })
  }, [])

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.Archived'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>
              <span className='text-muted text-hover-primary'>
                {intl.formatMessage({id: 'Menu.Home'})}
              </span>
            </li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Archived'})}
            </li>
          </ul>
        </div>
      </div>

        <div>
          <div className='card mb-3 mb-xl-5'>
            <div className='card-body pt-0 pb-0'>
              <div className='d-flex overflow-auto'>
                <Box sx={{width: '100%'}}>
                  <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={value} onChange={(e, val) => setValue(val)} aria-label='user-tabs'>
                      <Tab label={intl.formatMessage({id: 'Menu.Users'})} />
                    </Tabs>
                  </Box>
                </Box>
              </div>
            </div>
          </div>

          <div>
            {permissions.archiveUsers && value === 0 && (
              <ArchiveUsers   /> 
            )}
          </div>
        </div>
    </motion.nav>
  )
}

export default ArchivesHome
