import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {Form, Table} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import moment from 'moment'

export const VoucherModal = ({handleClose, show, data}) => {
  const intl = useIntl()

  if (!data?.length) {
    return null
  }

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({id: 'Table.Vouchers'})}</Modal.Title>
      </Modal.Header>

      <Modal.Body className='p-4'>
        <Table bordered hover striped className='text-xs'>
          <thead className='text-uppercase'>
            <tr>
              <th className='font-weight-bold text-center'>
                {intl.formatMessage({id: 'Table.ID'})}
              </th>
              <th className='font-weight-bold text-center'>
                {intl.formatMessage({id: 'Table.Serial'})}
              </th>
              <th className='font-weight-bold text-center'>
                {intl.formatMessage({id: 'Table.PIN'})}
              </th>
              <th className='font-weight-bold text-center'>
                {intl.formatMessage({id: 'Table.Time'})}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) =>
              item.purchase.map((order, orderIndex) => (
                <tr key={`${index}-${orderIndex}`}>
                  <td className='text-center'>{order.id}</td>
                  <td className='text-center'>{order.serial}</td>
                  <td className='text-center'>{order.pin}</td>
                  <td className='text-center'>
                    {moment(order.created_at).format('YYYY-MM-DD hh:mm:ss A')}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => handleClose(false)} className='mx-2'>
          {intl.formatMessage({id: 'Button.Close'})}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
