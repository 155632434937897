import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../../api/axios'
import Swal from 'sweetalert2'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Skeleton} from '@mui/material'
import useAuthContext from '../../../../Auth/AuthContext'
import moment from 'moment'

const Errors = ({data, loading}) => {
  const intl = useIntl()
  const [items, setItems] = useState([])

  console.log(data)

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.Error'}),
      accessor: 'error',
      Cell: (row) => <div className='w-100 flex-center'>{row.row.original.error}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Time'}),
      accessor: 'created_at',
      Cell: (row) => (
        <div className='w-100 flex-center'>
          {moment(row.row.original.created_at).format('YYYY-MM-DD hh:mm:ss A')}
        </div>
      ),
    },
  ]

  useEffect(() => {
    if (data) {
      setItems(data)
    }
  }, [data])

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      {items && !loading ? (
        <>
          <div className='d-none d-md-block'>
            <ReactTable columns={columns} data={items} customComponent={<></>} />
          </div>
        </>
      ) : (
        <div className='row'>
          <div className='col-12'>
            <Skeleton
              variant='rounded'
              animation='wave'
              sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
            />
          </div>
        </div>
      )}
    </motion.nav>
  )
}

export default Errors
