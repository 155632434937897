import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate, useParams} from 'react-router-dom'
import axiosInstance from '../../../../api/axios'
import Swal from 'sweetalert2'
import {motion} from 'framer-motion'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Box, Skeleton} from '@mui/material'
import useAuthContext from '../../../../Auth/AuthContext'
import {Images} from '../../../../../_metronic/partials/widgets/React_Table/Images'
import {VoucherModal} from '../VoucherModal'

export const OrderDetail = ({data, loading}) => {
  const intl = useIntl()

  const [vouchars, setVouchars] = useState(false)

  const TruncateString = ({text}) => {
    const truncatedText = text ? text.slice(0, 50) : ''
    return truncatedText
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'item.id',
      Cell: ({row}) => <div className='w-100 flex-center'># {row.original.item.id}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Description'}),
      accessor: 'item.name_en',
      Cell: (row) => (
        <div className='w-300 flex-center fs-12 f-bold'>
          <div>
            <TruncateString text={row.row.original.item.name_en} />
          </div>
          <div>
            <TruncateString text={row.row.original.item.name_ar} />
          </div>
        </div>
      ),
    },

    {
      Header: intl.formatMessage({id: 'Table.File'}),
      accessor: 'item.img',
      Cell: (row) => (
        <div className='symbol symbol-50px'>
          <img
            src={
              row.row.original.item.img
                ? process.env.REACT_APP_IMAGE_PATH + row.row.original.item.img
                : toAbsoluteUrl('media/avatars/300-6.jpg')
            }
            alt=''
          />
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Count'}),
      accessor: 'count',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.count}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Total'}),
      accessor: 'total',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.total}</div>,
    },

    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: (row) => (
        <>
          <div className='d-flex flex-center my-2'>
            <button onClick={(e) => setVouchars(true)} className='btn me-2 w-100  btn-light-dark'>
              {intl.formatMessage({id: 'Table.Vouchers'})}
            </button>
          </div>
        </>
      ),
    },
  ]

  return (
    <>
      <motion.nav
        initial={{opacity: 0, x: 100}}
        animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
        exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
      >
        {data && !loading ? (
          <ReactTable columns={columns} data={data.items} customComponent={<></>} />
        ) : (
          <div className='row'>
            <div className='col-12'>
              <Skeleton
                variant='rounded'
                animation='wave'
                sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
              />
            </div>
          </div>
        )}
      </motion.nav>
      <VoucherModal handleClose={setVouchars} data={data?.items} show={vouchars} />
    </>
  )
}
