import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Skeleton, Pagination} from '@mui/material'
import moment from 'moment'
import Swal from 'sweetalert2'
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import axiosInstance from '../../../../api/axios'

const ArchiveUsers = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 5 // Number of users per page

  const get_data = async () => {
    setLoading(true)
    const {data} = await axiosInstance.get('/admin/users/archived')
    setData(data)
    setLoading(false)
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const {data} = await axiosInstance.delete(`/admin/users/${id}/force-delete`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const handleRestore = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, restore it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const {data} = await axiosInstance.post(`/admin/users/${id}/restore`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'id',
      Cell: ({row}) => <div className='w-100 flex-center'># {row.original.id}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Name'}),
      accessor: 'name',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.name}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Phone'}),
      accessor: 'phone',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.phone}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Email'}),
      accessor: 'email',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.email}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.delete_at'}),
      accessor: 'created_at',
      Cell: ({row}) => (
        <div className='w-100 flex-center'>
          {moment(row.original.delete_at).format('YYYY-MM-DD hh:mm:ss A')}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: ({row}) => (
        <div className='d-flex flex-center w-100'>
          <button
            className='btn me-2 btn-light-success'
            onClick={() => handleRestore(row.original.id)}
          >
            {intl.formatMessage({id: 'Table.Restore'})}
          </button>

          <button
            className='btn me-2 btn-light-danger'
            onClick={() => handleDelete(row.original.id)}
          >
            {intl.formatMessage({id: 'Table.Delete'})}
          </button>
        </div>
      ),
    },
  ]

  useEffect(() => {
    get_data()
  }, [])

  const filteredData = data.filter((user) =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  )

  // Calculate the users to display on the current page
  const indexOfLastUser = currentPage * itemsPerPage
  const indexOfFirstUser = indexOfLastUser - itemsPerPage
  const currentUsers = filteredData.slice(indexOfFirstUser, indexOfLastUser)

  // Handle page change
  const handlePageChange = (event, page) => {
    setCurrentPage(page)
  }

  return (
    <div>
      {!loading ? (
        <>
          <div className='d-none d-lg-block'>
            <ReactTable columns={columns} data={data} customComponent={<></>} />
          </div>

          {/* Cards */}
          <div className='d-lg-none'>
            <div className='mb-4'>
              <input
                type='text'
                className='form-control'
                placeholder={intl.formatMessage({id: 'Search.Input'})}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className='row'>
              {currentUsers.map((user) => (
                <div key={user.id} className='col-12 mb-4'>
                  <div className='card shadow-sm p-3'>
                    <div className='card-body'>
                      <h5 className='card-title text-primary'># {user.id}</h5>
                      <p className='card-text'>
                        <strong>{intl.formatMessage({id: 'Table.Name'})}: </strong> {user.name}
                      </p>
                      <p className='card-text'>
                        <strong>{intl.formatMessage({id: 'Table.Phone'})}: </strong> {user.phone}
                      </p>
                      <p className='card-text'>
                        <strong>{intl.formatMessage({id: 'Table.Email'})}: </strong> {user.email}
                      </p>
                      <p className='card-text'>
                        <strong>{intl.formatMessage({id: 'Table.delete_at'})}: </strong>
                        {moment(user.delete_at).format('YYYY-MM-DD hh:mm:ss A')}
                      </p>
                      <div className='d-flex justify-content-between'>
                        <button className='btn btn-success' onClick={() => handleRestore(user.id)}>
                          <i className='fas fa-undo'></i>{' '}
                          {intl.formatMessage({id: 'Table.Restore'})}
                        </button>
                        <button className='btn btn-danger' onClick={() => handleDelete(user.id)}>
                          <i className='fas fa-trash-alt'></i>{' '}
                          {intl.formatMessage({id: 'Table.Delete'})}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Pagination  */}
            <div className='d-block d-lg-none mt-6 d-flex justify-content-center'>
              <Pagination
                count={Math.ceil(filteredData.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color='primary'
              />
            </div>
          </div>
        </>
      ) : (
        <div className='row'>
          <div className='col-12'>
            <Skeleton
              variant='rounded'
              animation='wave'
              sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ArchiveUsers
